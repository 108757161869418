import React from 'react';
import theme from 'themes/theme';
import './App.css';

// custom components
import Root from 'components/Root';

// material-ui
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

function App() {
  const muiTheme = createMuiTheme({
    palette: { ...theme },
    typography: {
      fontFamily: [
        'Poppins',
      ].join(','),
    },
  });

  return (
    <MuiThemeProvider theme={muiTheme}>
      <Root />
    </MuiThemeProvider>
  );
}

export default App;
