import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// material-ui
import { withStyles } from '@material-ui/core/styles';

// components
import App from 'components/App';

// styles
import styles from './styles';


const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch => ({

});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };


  render() {
    return (
      <App />
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Root));
