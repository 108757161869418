import Slider from "@material-ui/core/Slider";
// material-ui
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      value: 1,
    };
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    const lowPrice = 62;
    const price = 95;
    const maxQuantity = 100;
    const minQuantity = 1;
    const c = maxQuantity / minQuantity;

    return (
      <div
        style={{
          fontFamily: "Poppins",
          color: "#272839",
          textAlign: "center",
          background: "#F4F4F4",
          borderRadius: 4,
        }}
      >
        <span style={{ fontSize: 20 }}>$</span>{" "}
        <span style={{ fontSize: 34 }}>
          {Math.round(
            ((lowPrice - price) / (maxQuantity - minQuantity)) * value +
              (c * price - lowPrice) / (c - 1)
          )}
        </span>
        <br />
        per credit
        <br />
        <span style={{ fontSize: 12 }}>
          ({value} credit{value > 1 ? "s" : ""} batch prepaid)
        </span>
        <br />
        <Slider
          valueLabelDisplay="auto"
          onChange={(e, v) => this.setState({ value: v })}
          value={value}
          step={1}
          style={{
            maxWidth: 200,
          }}
          classes={{
            root: classes.root,
            thumb: classes.thumb,
            active: classes.active,
            valueLabel: classes.valueLabel,
            track: classes.track,
            rail: classes.rail,
          }}
          min={1}
          max={100}
        />
      </div>
    );
  }
}
export default withStyles(styles)(App);
